<template>
  <v-menu offset-y top :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <div class="pa-0">
          <v-icon
            v-if="!running"
            :disabled="disable"
            color="green"
            @click.stop="STORE_start"
          >
            mdi-play-circle
          </v-icon>
          <v-icon
            v-else
            :disabled="disable"
            color="error"
            @click.stop="STORE_stop"
          >
            mdi-stop-circle
          </v-icon>
          <span class="ml-2">{{ formattedElapsedTime }}</span>
        </div>
      </v-btn>
    </template>

    <StopWatchHistorico
      v-if="!running & (historico_atividade !== null)"
      :atividade="historico_atividade"
    />
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// import { format } from "date-fns";

export default {
  name: "StopWatch",

  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    atividade_id: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    StopWatchHistorico: () => import("./StopWatchHistorico.vue"),
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("Stopwatch", {
      running: (state) => state.running,
      historico_atividade: (state) => state.historico_atividade,
    }),
    ...mapGetters("Stopwatch", ["formattedElapsedTime"]),
  },

  methods: {
    ...mapActions("Stopwatch", {
      STORE_check: "check",
      STORE_start: "start",
      STORE_stop: "stop",
      STORE_stopTimer: "stopTimer",
      STORE_getHistorico: "getHistorico",
    }),
  },

  created() {
    this.STORE_check();
  },

  beforeDestroy() {
    this.STORE_stopTimer();
  },
};
</script>

<style></style>
